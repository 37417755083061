import { useEffect } from "react";
import i18next from "i18next";
import { navigate } from "gatsby";

export function useLanguageRedirect(url = "") {
  useEffect(() => {
    if (i18next.language === "pt-BR") {
      navigate(`/pt${url}`);
    }
  }, [url]);
}
