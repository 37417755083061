import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import { useLanguageRedirect } from "../hooks";
import { SEO } from "../components/SEO";

export const AboutPageTemplate = ({
  title,
  path,
  page,
  language,
  content,
  contentComponent,
}) => {
  const PageContent = contentComponent || Content;

  useLanguageRedirect(path);

  return (
    <section className="section section--gradient">
      <SEO
        title={page.title}
        description={page.description}
        url={language.includes("en") ? path : `/${language}/${path}`}
      />
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section">
              <h2 className="title is-size-3 is-bold-light">{title}</h2>
              <PageContent className="content" content={content} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <AboutPageTemplate
        contentComponent={HTMLContent}
        page={post.frontmatter.page}
        path={post.frontmatter.path}
        language={post.frontmatter.language}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  );
};

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        page {
          title
          description
        }
        title
        language
        path
      }
    }
  }
`;
